<template>
  <div class="content">
    <div class="header">
      <img src="../assets/bg-header.png" alt="" />
    </div>
    <div class="login-state">
      <div v-if="noLogin" @click="$router.push('/login')">欢迎您，请先登录</div>
      <!-- <el-popconfirm title="" hide-icon cancel-button-text="" confirm-button-text="退出" @confirm="logout" v-else>
        <div slot="reference">欢迎您，{{ userName }}</div>
      </el-popconfirm> -->
      <el-dropdown v-else>
        <span class="el-dropdown-link"> 欢迎您，{{ userName }} </span>
        <el-dropdown-menu slot="dropdown" :append-to-body="false">
          <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="center">
      <div class="center-top">
        <h1>数字化服务</h1>
        <p>电气风电提供全生命周期数字化服务软件，为客户提供智慧运维一站式解决方案<br />让决策更高效，运维更智能<br />通过数据赋能和技术创新，为客户创造更多价值</p>
        <div class="project-list">
          <div class="project-item">
            <div class="project-item-icon"><img class="windseer-icon" src="../assets/windseer-icon.png" alt="" /></div>
            <h2>WindSeer</h2>
            <h4>风功率预测系统</h4>
            <p>精准预测满足电网要求<br />最大限度确保风场年收益率</p>
            <!-- <a class="project-item-btn" href="https://iwind.sewpg.com/windseer/" target="_blank"> -->
            <span class="project-item-btn" @click="toRouter('https://iwind.sewpg.com/windseer/')">
              <em></em>
              <span>点击进入</span>
            </span>
          </div>
          <div class="project-item">
            <div class="project-item-icon"><img class="prognostic-agent-icon" src="../assets/prognostic-agent-icon.png" alt="" /></div>
            <h2>Prognostic Agent</h2>
            <h4>风场故障预测及健康管理系统</h4>
            <p>基于数字孪生模型<br />高精度故障预警，全方位健康评估</p>
            <span class="project-item-btn" @click="toRouter('https://iwind.sewpg.com/prognostic-agent/')">
              <em></em>
              <span>点击进入</span>
            </span>
          </div>
          <div class="project-item">
            <div class="project-item-icon"><img class="fleet-agent-icon" src="../assets/fleet-agent-icon.png" alt="" /></div>
            <h2>Fleet Agent</h2>
            <h4>风电智能运维</h4>
            <p>高效便捷智能运维服务<br />提供多业务场景完整闭环方案</p>
            <span class="project-item-btn" @click="toRouter('https://iwind.sewpg.com/fleet-agent/')">
              <em></em>
              <span>点击进入</span>
            </span>
          </div>
          <div class="project-item">
            <div class="project-item-icon"><img class="fro-icon" src="../assets/fro-icon.png" alt="" /></div>
            <h2>FRO门户</h2>
            <h4>风电智能运维</h4>
            <p>一站式客户交互<br />建立数字化客户链接通道，一触即达</p>
            <span class="project-item-btn" @click="toRouter('https://iwind.sewpg.com/fleet-sight/')">
              <em></em>
              <span>点击进入</span>
            </span>
          </div>
        </div>
      </div>
      <div class="center-bottom">
        <h1>数字化产品</h1>
        <p>数字基因深度融合<br />提供风机边缘端、风场端，智能感知与智慧监控<br />数字智联赋能智能风机与智慧风场</p>
        <div class="project-list">
          <div class="project-item">
            <div class="project-item-img">
              <img src="../assets/edge-agent-pic.png" alt="" />
            </div>
            <h2>Edge-Agent</h2>
            <h4>智能终端与远程听诊</h4>
            <p>高频全息采集，挖掘数据价值</p>
          </div>
          <div class="project-item">
            <div class="project-item-img">
              <img src="../assets/park-agent-pic.png" alt="" />
            </div>
            <h2>Park-Agent</h2>
            <h4>风场智能监控</h4>
            <p>高效管理，多维呈现，模块化分析，精细化监控</p>
          </div>
          <div class="project-item">
            <div class="project-item-img">
              <img src="../assets/gird-agent-pic.png" alt="" />
            </div>
            <h2>Gird-Agent</h2>
            <h4>风场智能控制</h4>
            <p>电网友好，环境友好，增功增效，协调最优</p>
          </div>
          <div class="project-item">
            <div class="project-item-img">
              <img src="../assets/health-agent-pic.png" alt="" />
            </div>
            <h2>Health-Agent</h2>
            <h4>智能诊断</h4>
            <p>机理融合，精确模型，智能诊断，性能提升</p>
          </div>
        </div>
      </div>
      <div class="footer">
        <span class="copy">&copy;上海电气风电集团股份有限公司版权所有&nbsp;2019</span><span class="divider">|</span
        ><a href="https://beian.miit.gov.cn/ " class="grey" target="_blank">沪ICP备2021011758号-1</a><span class="divider">|</span
        ><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402009133" target="_blank"
          ><img src="../assets/ghs.png" style="vertical-align: middle" />沪公网安备&nbsp;31010402009133号</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sys-index",
  props: {
    msg: String,
  },
  data() {
    return {
      noLogin: true,
      userName: "",
      cookieValue: "",
      intervalId: null,
    };
  },
  created() {
    this.checkCookie();
    this.intervalId = setInterval(this.checkCookie, 1000);
  },
  destroyed() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },

  methods: {
    checkCookie() {
      const newCookieValue = this.getCookies("SEC_AUTH"); // 替换'myCookieName'为你要监听的cookie名称
      if (newCookieValue !== this.cookieValue) {
        this.cookieValue = newCookieValue;
        if (!this.cookieValue) {
          this.noLogin = true;
          this.userName = "";
        } else {
          this.noLogin = false;
          setTimeout(() => {
            this.userName = this.getCookies("SEC_USER");
          }, 1000);
        }
        // 这里可以添加cookie变化时的处理逻辑
        console.log("Cookie changed");
      }
    },
    clearCookie(name) {
      document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },
    logout() {
      this.$axios
        .delete("/api/system/logout", {
          headers: {
            Authorization: "Bearer " + this.getCookies("SEC_AUTH"),
          },
        })
        .then(() => {
          // if (res.data.code == 200) {
            this.clearCookie("SEC_AUTH");
            this.clearCookie("SEC_USER");
            this.clearCookie("SEC_EXPIRES");
            this.noLogin = true;
            this.userName = "";
            this.$notify({
              title: "已退出",
              type: "success",
            });
          // } else {
          //   console.log(res);
          // }
        })
        .catch(() => {});
    },
    toRouter(path) {
      if (this.noLogin) {
        const routeUrl = this.$router.resolve({
          path: "/login",
          query: {
            redirect: encodeURIComponent(path),
          },
        });
        window.open(routeUrl.href, "_blank");
      } else {
        window.open(path, "_blank");
      }
    },
    getCookies(name) {
      const allCookies = document.cookie.split("; ");
      const cookies = {};

      allCookies.forEach((cookie) => {
        const [key, value] = cookie.split("=");
        cookies[key] = value;
      });

      return cookies[name];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.content {
  width: 100%;
  min-width: 1000px;
}

.header {
  width: 100%;
}

.header img {
  width: 100%;
}

h1 {
  margin: 0;
  line-height: 2.2vw;
  color: #04202e;
  font-size: 1.56vw;
  text-align: center;
  font-weight: 500;
}

h2 {
  margin: 0;
  line-height: 1.46vw;
  color: #04202e;
  font-size: 1.04vw;
}

h4 {
  margin: 0;
  line-height: 1.04vw;
  color: #000;
  font-size: 0.73vw;
}

p {
  margin: 0;
  line-height: 1.25vw;
  color: rgba(4, 32, 46, 0.5);
  font-size: 0.73vw;
}

.center-top {
  margin: 0 auto;
  padding: 2.3vw 0 3.44vw;
  width: 59.38vw;
  min-width: 808px;
  background-color: #fff;
}

.center-top > p {
  margin: 0.94vw 0 2.34vw;
  text-align: center;
}

.project-list {
  display: flex;
  justify-content: space-between;
}

.center-top .project-item {
  width: 11.67vw;
  min-width: 158px;
  text-align: center;
}

.center-top .project-item-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 0.52vw;
  height: 3.854vw;
}

.center-top .project-item-icon .windseer-icon {
  width: 4.167vw;
  min-width: 57px;
  height: 3.854vw;
  min-height: 52px;
}

.center-top .project-item-icon .prognostic-agent-icon {
  width: 4.167vw;
  min-width: 57px;
  height: 3.75vw;
  min-height: 51px;
}

.center-top .project-item-icon .fleet-agent-icon {
  width: 3.646vw;
  min-width: 50px;
  height: 3.646vw;
  min-height: 50px;
}

.center-top .project-item-icon .fro-icon {
  margin-top: 0.417vw;
  width: 4.167vw;
  min-width: 57px;
  height: 2.656vw;
  min-height: 36px;
}

.center-top .project-item h4 {
  margin: 0.52vw 0 0.26vw;
}

.project-item p {
  white-space: nowrap;
}

.center-top .project-item-btn {
  display: flex;
  margin: 1vw auto 0;
  width: 5.2vw;
  height: 1.67vw;
  line-height: 1.67vw;
  font-size: 0.73vw;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(90deg, #4fce76 0%, #1a9af1 100%, #3478e2 100%);
  border-radius: 0.83vw;
  cursor: pointer;
}

.center-top .project-item-btn em {
  display: inline-block;
  margin: 0 0.31vw;
  width: 1.04vw;
  height: 100%;
  background: url(../assets/icon-link.png) no-repeat center;
  background-size: contain;
}

.center-bottom {
  padding: 1.88vw 0 17vw;
  background-color: #f7f9fb;
}

.center-bottom > p {
  margin: 1.6vw auto 2.9vw;
  text-align: center;
}

.center-bottom .project-list {
  margin: 0 auto;
  width: 80.8vw;
  min-width: 1000px;
}

.center-bottom .project-item {
  box-sizing: border-box;
  padding: 1.46vw;
  width: 18.2vw;
  min-width: 247px;
  text-align: left;
  background: #fff;
}

.center-bottom .project-item-img {
  margin-bottom: 0.52vw;
  width: 15.3vw;
  min-width: 208px;
  height: 8.6vw;
  min-height: 117px;
}

.center-bottom .project-item-img img {
  width: 100%;
  height: 100%;
}

.center-bottom .project-item h4 {
  margin-top: 0.3vw;
}

.footer {
  display: flex;
  justify-content: center;
  height: 70px;
  line-height: 70px;
  font-size: 14px;
  color: rgb(102, 102, 102);
  background-color: #1e1e1e;
}

.footer .copy {
  font-size: 14px;
  text-align: center;
}

.footer .divider {
  margin: 0 10px;
}

.footer a {
  text-decoration: none;
  color: rgb(51, 122, 183);
}
.footer a:hover {
  color: #23527c;
}

.login-state {
  position: absolute;
  top: 16px;
  right: 20px;
  color: #f5f5f5;
  cursor: pointer;
  font-size: 13px;
}
.el-dropdown {
  color: #f5f5f5;
}

/deep/.el-dropdown-menu {
  background: #8795b1;
  padding: 0;
  color: #f5f5f5;
  border: none;
  width: 70px;
  .popper__arrow::after {
    border-bottom-color: #8795b1 !important;
  }
  .el-popper[x-placement^="bottom"] .popper__arrow {
    border-bottom-color: #8795b1 !important;
    top:0;
  }
}
.el-dropdown-menu__item,
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  border-radius: 4px;
  background: #8795b1;
  color: #f5f5f5;
}
</style>
