import Vue from 'vue'
import App from './App.vue'
import './styles/index.less'
import router from './router'
import {
  Button, Input, 
  Icon, 
  Message, 
  Notification, 
  Popconfirm, 
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
Vue.use(Button)
Vue.use(Input)
Vue.use(Icon)
Vue.use(Popconfirm)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.config.productionTip = false
Vue.prototype.$message = Message
Vue.prototype.$notify = Notification
Vue.prototype.$axios = axios

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
